import React, {useEffect, useMemo, useState, memo, useCallback} from "react";
import {connect} from "react-redux";
import {
    getuserinfo, 
    tweetcodeState, 
    relogin, 
    getUrlParams, 
    logoutSelf, 
    getConfig,
    getTokenBalanceOf,
} from "@/utils/common";
import {useNavigate, useLocation} from "react-router-dom";
import Navs from "src/common/MainNavs";
import {usePrivy, useWallets} from "@privy-io/react-auth";
import {url} from "@/utils/configUri";
import http from "@/utils/axios";
import {
    balancePath,
    gameTrillionaireAssetsPath,
    gameTrillionaireBattlePath,
    gameTrillionaireEcosystemPath,
    gameTrillionaireMissionPath,
    invitePath,
    mainExplorePath,
    mainHomePath,
    mainMyclubsPath,
    mainProfilePath,
    mainNftNftPanelPath,
    _main,
    mainClubAllClubs,
    gameMemeClubPath,
    gameMemeClubAssetsPath,
    gameMemeClubTgePath,
    gameMemeClubLauncherPath,
    gameMomentClubPath,
    gameMomentClubExplorePath,
    gameMomentClubLauncherPath,
    gameMomentClubTgePath,
    gameMomentClubAssetsPath,
    gameMomentClubDetailPath,
    publicQuickLinkMoments,
    gameMomentReferralPath,
} from "@/routes/config";
import GameTrillionaireNavs from "src/common/GameTrillionaireNavs";
import AntDrawer from "@/common/AntDrawer";
import GasSetting from "@/model/GasSetting";
import {useTranslation} from "react-i18next";
import {
    moonpayBasicDollar,
    LfgMainnetId,
    LfgTestnetId,
    MaticMainnetId,
    MaticTestnetId,
    isOnlineEnv,
} from "@/utils/env";
import GameMemeClubNavs from "@/common/GameMemeClubNavs";
import NewGameMemeClubNavs from "@/common/NewGameMemeClubNavs";
import { defaultMemeSwitchVideoParams, momentClubList } from "@/utils/momentClub";

const showNavArr = [
    mainHomePath,
    mainExplorePath,
    mainMyclubsPath,
    mainProfilePath,
    mainNftNftPanelPath,
    mainClubAllClubs,
]

const showTrillionaireNavsArr = [
    gameTrillionaireAssetsPath,
    gameTrillionaireBattlePath,
    gameTrillionaireEcosystemPath,
    gameTrillionaireMissionPath
]

const showMemeClubNavArr = [
    gameMemeClubPath,
    gameMemeClubAssetsPath,
    gameMemeClubTgePath,
    gameMemeClubLauncherPath,
]

const showNewMemeClubNavArr = [
    gameMomentClubPath,
    gameMomentClubExplorePath,
    gameMomentClubLauncherPath,
    gameMomentClubTgePath,
    gameMomentClubAssetsPath,
]

// import s from "./index.module.less"
const PublicView = (
    {
        showBalance,
        showLink,
        userInfo,
        setUserInfo,
        setShowBalance,
        setShowLink,
        defaultInviteCode,
        setDefaultInviteCode,
        showGasMode,
        setShowGasMode,
        walletBalanceList,
        setWalletBalanceList,
        setCurrentMomentClubId,
        hideNSFW,
        setSwitchVideoList,
        setSwitchVideoListCursor,
    }
) => {
    // const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const [userInfoDetail, setUserInfoDetail] = useState(null);

    // const {db} = React.useContext(StoreContext);
    const {userId, selfData} = useMemo(() => {
        return userInfo || {}
    }, [userInfo])

    const {
        bindReferralCode,
        referralCode
    } = useMemo(() => {
        return selfData || {}
    }, [selfData])

    const getTweetMaticBindResult = () => {
        tweetcodeState().then(res => {
            const {hasCheck, retCode} = res;
            if (hasCheck) {
                // setTweetMaticBindResult(retCode);
                console.log(`tweetcodeState`, res);

            }
        }).catch(e => {
        })
    }

    const setUserData = (res) =>{
        const {payEntryFee, buyFirstKey, uid} = res?.selfData || {};
        const hasLink = !!uid;
        if (payEntryFee && buyFirstKey) {
            setShowBalance(false)
            // db.update({
            //     showBalance: false,
            // })
        }
        if (hasLink) {
            setShowLink(false)
            // db.update({
            //     showLink: false,
            // })
        }

        if (!buyFirstKey && res?.holding) {
            /*http('post', url.check_buyfirstkey, {}).then((res) => {
            }).catch(e => {
            });*/

        }


        setUserInfo({
            ...userInfo,
            ...res
        })
        // db.update({
        //     // userInfo: res?.data?.user,
        //     userInfo: {
        //         ...db.get('userInfo'),
        //         ...res
        //     }
        // });
        // db.set();
    }

    useEffect(() => {
        if (userInfoDetail){
            setUserInfoDetail(null);
            let lfgId = isOnlineEnv() ? LfgMainnetId : LfgTestnetId;
            let maticId = isOnlineEnv() ? MaticMainnetId : MaticTestnetId;
            if (userInfoDetail?.selfData){
                if (walletBalanceList?.[lfgId] > 0){
                    userInfoDetail.selfData.lfgBalance = walletBalanceList?.[lfgId];
                }
                if (walletBalanceList?.[maticId] > 0){
                    userInfoDetail.selfData.balance = walletBalanceList?.[maticId];
                }
            }
            setUserData(userInfoDetail);

            let tokenList = [lfgId, maticId];
            getTokenBalanceOf(userInfoDetail?.walletAddr, tokenList).then(balanceRes => {
                let tempBalance = {...walletBalanceList} || [];
                for (let i = 0; i < tokenList.length; i++) {
                    tempBalance[tokenList[i]] = balanceRes?.balances?.[tokenList[i]];
                }
                setWalletBalanceList(tempBalance);
                if (userInfoDetail?.selfData){
                    userInfoDetail.selfData.lfgBalance = balanceRes?.balances?.[lfgId];
                    userInfoDetail.selfData.balance = balanceRes?.balances?.[maticId];
                    userInfoDetail.balance = balanceRes?.balances?.[maticId];
                }
                setUserData(userInfoDetail);
            }).catch(err => {
                console.error(err.message);
            })
        }
    }, [userInfoDetail])

    const reFresh = () => {
        // console.log(`reFresh`);
        userId && getuserinfo().then(res => {
            setUserInfoDetail(res);
        }).catch(e => {
            console.log(e);
        })
    }


    const reloginFn = useCallback(() => {
        userId && relogin()
        userId && window?.progressier?.add({
            id: userId.toString()
        })
    }, [userId])

    const registerPageVisibility = () => {
        let hidden;
        let visibilityChange;
        if (typeof document.hidden !== 'undefined') {
            hidden = 'hidden';
            visibilityChange = 'visibilitychange';
        } else if (typeof document.msHidden !== 'undefined') {
            hidden = 'msHidden';
            visibilityChange = 'msvisibilitychange';
        } else if (typeof document.webkitHidden !== 'undefined') {
            hidden = 'webkitHidden';
            visibilityChange = 'webkitvisibilitychange';
        }
        window.document.addEventListener(visibilityChange, () => {
            if (!document[hidden]) {
                reFresh()
                reloginFn()
                // getTweetMaticBindResult()
            } else {
            }
        });
    }
    useEffect(() => {
        let code = getUrlParams('referralcode');

        if (code && code.indexOf('#') > -1) {
            code = code.slice(0, code.indexOf('#'));
        }
        if (code) {
            console.log(code);
            if (defaultInviteCode !== code) {
                setDefaultInviteCode(code);

            }
        }
    }, [location])

    // useEffect(() => {
    //     console.log(`defaultInviteCode`, defaultInviteCode || null);
    //     userId && defaultInviteCode && !userInfo?.selfData?.bindReferralCode && navigate(invitePath)
    // }, [defaultInviteCode])


    
    const goMomentClub = () => {
        let params = defaultMemeSwitchVideoParams;
        momentClubList({
            rankType: params.rankType,
            coinId: params.coinId,
            priceRange: params.priceRange,
            progressRange: params.progressRange,
            lpRange: params.lpRange,
            onlyFavorite: params.onlyFavorite,
            onlyHeld: params.onlyHeld,
            onlyLive: params.onlyLive,
            isAsc: params.isAsc,
            cursor: ""
        }).then(res => {
            let _list = []; 
            if (res?.clubList?.length > 0){
                for (let i = 0; i < res?.clubList?.length; i++){
                    let stateCode = res?.clubList[i].profile.momentAuditCode;
                    if (stateCode !== 2 || (stateCode === 2 && !hideNSFW)){
                        _list.push({
                            clubId: res?.clubList[i].userId,
                            momentAuditCode: stateCode,
                            videoId: res?.clubList[i].videoId,
                            videoUrlMap: res?.clubList[i].videoUrlMap,
                            coverUrl: res?.clubList[i].profile.avatarUrl,
                            videoOriHeight: res?.clubList[i].videoOriHeight,
                            videoOriWidth: res?.clubList[i].videoOriWidth,
                            videoFirstFrameUrl: res?.clubList[i].videoFirstFrameUrl,
                        });
                    }
                }
            }
            let index = -1;
            if (_list?.length > 0){
                for (let i = 0; i < _list?.length; i++){
                    if (_list[i]?.momentAuditCode !== 3){
                        index = i;
                        break;
                    }
                }
            }
            setSwitchVideoList(_list);
            if (res?.nextCursor === ""){
                setSwitchVideoListCursor("-1");
            }
            else{
                setSwitchVideoListCursor(res?.nextCursor);
            }
            if (index >= 0){
                setCurrentMomentClubId(_list[index].clubId);
                navigate(`${gameMomentClubDetailPath}`,{replace:true,state: {backPath:gameMomentClubExplorePath}});
                
            }
            else{
                navigate(gameMomentClubExplorePath);
            }
        }).catch(e => {
            console.error(e);
            navigate(gameMomentClubExplorePath);
        })
    }

    useEffect(() => {
        if (selfData){
            const isInMomentGame = location.pathname.startsWith(gameMomentClubPath);
            if ((window.isMomentsLink || isInMomentGame) && userId){
                if (!bindReferralCode){
                    navigate(gameMomentReferralPath, {replace: true});
                }
                /*else if (location.pathname.indexOf('/'+publicQuickLinkMoments) !== 0){
                    goMomentClub();
                }*/
            }
        }
    }, [location, selfData, userId, bindReferralCode]);

    useEffect(() => {
        let timer = null;
        registerPageVisibility();
        if (!timer && userId) {
            reFresh()
            timer = setInterval(() => {
                reFresh()
            }, 5000)
        }
        return () => {
            timer && clearInterval(timer);
        }
    }, [userId])

    const _showBalance = useMemo(() => {
        return showBalance
    }, [showBalance])
    const _showLink = useMemo(() => {
        return showLink
    }, [showLink])

    const _showNav = useMemo(() => {
        // if(showBalance || showLink) return false
        for (let i = 0; i < showNavArr.length; i++) {
            if (location.pathname.endsWith(showNavArr[i])) {
                return true
                break;
            }
        }

    }, [showBalance, showLink, location])


    const _showTrillionaireNavs = useMemo(() => {
        for (let i = 0; i < showTrillionaireNavsArr.length; i++) {
            if (location.pathname.endsWith(showTrillionaireNavsArr[i])) {
                return true
                break;
            }
        }
    }, [location])

    const _showMemeClubNav = useMemo(() => {
        
        for (let i = 0; i < showMemeClubNavArr.length; i++) {
            if (location.pathname.endsWith(showMemeClubNavArr[i])) {
                return true
                break;
            }
        }
    }, [location])

    const _showNewMemeClubNav = useMemo(() => {
        for (let i = 0; i < showNewMemeClubNavArr.length; i++) {
            if (location.pathname.endsWith(showNewMemeClubNavArr[i])) {
                return true
                break;
            }
        }
    }, [location])


    const {ready, authenticated, logout} = usePrivy()
    const {wallets} = useWallets();

    const checkWallet = async () => {
        try {
            const embeddedWallet = wallets.find((wallet) => (wallet.walletClientType === 'privy'));
            const provider = await embeddedWallet.getEthersProvider(); // ethers provider object

            const {chainId} = await getConfig();

            await embeddedWallet.switchChain(chainId);
            return true;
        } catch (e) {
            return false;
        }
    }

    useEffect(
        () => {
            if (location.pathname === '/home' || location.pathname === '/chats' || location.pathname === '/wishes' || location.pathname === '') {
                if (ready && !authenticated) {
                    logout();
                    logoutSelf();
                }

                if (ready && authenticated) {
                    checkWallet().then(res => {
                        if (!res) {
                            logout();
                            logoutSelf();
                        }
                    })
                }
            }
        }, [location]
    )

    return (
        <>
            {/* {_showBalance && <Balance />}
      {_showLink && <Link />} */}
            {_showNav && <Navs/>}
            {_showTrillionaireNavs && <GameTrillionaireNavs></GameTrillionaireNavs>}
            {_showMemeClubNav && <GameMemeClubNavs></GameMemeClubNavs>}
            {_showNewMemeClubNav && <NewGameMemeClubNavs></NewGameMemeClubNavs>}
            <AntDrawer
                title={'Gas Mode'}
                placement={'right'}
                onClose={() => setShowGasMode(false)}
                width={300}
                open={showGasMode}
                className="hireFilter gasFilter"
                zIndex={7778}
            >
                <GasSetting
                    closeHandle={() => {
                        setShowGasMode(false);
                    }}
                />
            </AntDrawer>
        </>


    )
}
const mapStateToProps = ({app}) => {
    return {
        showOtherTransContent: app.showOtherTransContent,
        showSettings: app.showSettings,
        showBalance: app.showBalance,
        showLink: app.showLink,
        userInfo: app.userInfo,
        defaultInviteCode: app.defaultInviteCode,
        showGasMode: app.showGasMode,
        walletBalanceList: app.walletBalanceList,
        currentMomentClubId: app.currentMomentClubId,
        hideNSFW: app.hideNSFW,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setUserInfo: (params) => {
            return dispatch({
                type: "app/setUserInfo",
                payload: params
            });
        },
        setShowBalance: (params) => {
            return dispatch({
                type: "app/setShowBalance",
                payload: params
            });
        },
        setShowLink: (params) => {
            return dispatch({
                type: "app/setShowLink",
                payload: params
            });
        },
        setDefaultInviteCode: (params) => {
            return dispatch({
                type: "app/setDefaultInviteCode",
                payload: params
            });
        },
        setShowGasMode: (params) => {
            return dispatch({
                type: "app/setShowGasMode",
                payload: params
            });
        },
        setWalletBalanceList: (params) => {
            return dispatch({
                type: "app/setWalletBalanceList",
                payload: params,
            });
        },
        setCurrentMomentClubId: (params) => {
            return dispatch({
                type: "app/setCurrentMomentClubId",
                payload: params,
            });
        },
        setSwitchVideoListCursor: (params) => {
            return dispatch({
                type: "app/setSwitchVideoListCursor",
                payload: params,
            });
        },
        setSwitchVideoList: (params) => {
            return dispatch({
                type: "app/setSwitchVideoList",
                payload: params,
            });
        },
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(PublicView));
