// import logo from './logo.svg';
import './App.css';
import {Provider} from "react-redux";
import store from "./store";
import {ConfigProvider} from "antd";
import Routes from './routes';
import StoreContextProvider from "./contexts/StoreContext";
import initListener from './utils/WebIMListener';
import {Suspense, useEffect, useRef, useState} from 'react';
import {rtc, leave} from './utils/basicVoiceCall';
import {useLocation} from 'react-router-dom';
// import {visibilityChangeEvent,onVisibilityChange} from "@/utils/common"
import PublicView from './views/public/Checking';
import {envConfig} from "@/utils/env";

function App() {
    const location = useLocation();
    const appRef = useRef(null);
    // document.addEventListener(visibilityChangeEvent, onVisibilityChange);
    useEffect(() => {
        initListener()
        window.onpopstate = function (e) {
            // 业务逻辑
            if (location.pathname.indexOf('/chats/') === -1 && rtc.joinState) {
                leave()
            }
        };
        return () => {
            // 回退事件只用于当前组件，则需要在组件销毁时把回退事件销毁
            window.onpopstate = null;
        };
    }, [])

    const [showOrientationTip, setShowOrientationTip] = useState(false);

    window.addEventListener('orientationchange', function (event) {

        if (window.orientation == 90 || window.orientation == -90) {
            setShowOrientationTip(true);
        } else {
            setShowOrientationTip(false)
        }

    });

    function setUsableWidth(){
        if (appRef?.current){
            const appStyle = window.getComputedStyle(appRef.current);
            document.documentElement.style.setProperty("--usable-width", appStyle.width);
        }
    }

    window.addEventListener('resize', function (event) {
        setUsableWidth();
    });

    // heartbeat ta push every 600s
    useEffect(() => {
        setUsableWidth();

        const timer = setInterval(() => {
            if (envConfig.taEnable) {
                window.ta && window.ta.quick("autoTrack");
            }
        }, 600000)
        return () => {
            clearInterval(timer)
        }
    }, [])

    return (
        <ConfigProvider
            autoInsertSpaceInButton={false}
        >
            <StoreContextProvider>
                <div ref={appRef} className="App">
                    <Provider store={store}>
                        <Suspense>
                            <Routes/>
                            <PublicView/>
                        </Suspense>
                       
                    </Provider>
                </div>
                {showOrientationTip && <div className="orientationTip">Please keep your phone in portrait mode</div>}
            </StoreContextProvider>
        </ConfigProvider>

    );
}

export default App;
